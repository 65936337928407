import React from 'react';

import { getWidgetInitialiser } from '../../../../sharedModules/getWidgetInitialiser';
import { loadCSS } from '../../../../utils/loadCSS';
import { InitialiserProps, WidgetWrapper } from '../../../types/InitialiserProps';
import type { WidgetProps } from '../../../types/WidgetProps';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import SimpleContracts from '../Contracts/SimpleContracts';
import SimpleRetail from '../Retail/SimpleRetail';
import SimpleSubscriptions from '../Subscriptions/SimpleSubscriptions';

loadCSS('simple');

interface SimpleProps {
  getDealData: WidgetProps['getDealData'];
  dealData: WidgetProps['dealData'];
  getWidgetResponse: WidgetProps['getWidgetResponse'];
  postForWidgetResponse: WidgetProps['postForWidgetResponse'];
  getAiredaleFeeds: WidgetProps['getAiredaleFeeds'];
  getModelSuggestions: WidgetProps['getModelSuggestions'];
  sendAnalytics: WidgetProps['sendAnalytics'];
  setDealData: WidgetProps['setDealData'];
  translate: WidgetProps['translate'];
  genericSharedComponents: WidgetProps['genericSharedComponents'];
}

const Simple: React.FC<SimpleProps> = (props) => {
  const {
    genericSharedComponents: { HawkWidgetAdvanced, Main },
  } = props;
  return (
    <HawkWidgetAdvanced
      {...{
        ...props,
        selectedSwitcherItem: 'data',
        switcherItems: ['data', 'texts', 'minutes'],
      }}
    >
      <Consumer>
        {({ activeTab }): JSX.Element => {
          return (
            <Main
              {...{
                ...props,
                className: 'simple',
              }}
            >
              {((): JSX.Element => {
                switch (activeTab?.category) {
                  case 'subscriptions':
                    return <SimpleSubscriptions />;
                  case 'contracts':
                    return <SimpleContracts />;
                  default:
                    return <SimpleRetail />;
                }
              })()}
            </Main>
          );
        }}
      </Consumer>
    </HawkWidgetAdvanced>
  );
};

Simple.getInitialiserProps = async ({
  renderProps: { el, site, articleUrl, ...restRenderProps },
  attributes,
  getWidgetResponse,
  getSeasonalResponse,
  getAiredaleFeeds,
  getModelSuggestions,
  postForWidgetResponse,
  editorial,
  defaultParams,
  url,
  origin,
  dataLinkMerchant,
}): InitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    url,
    origin,
    dataLinkMerchant,
    site,
    getWidgetResponse,
    getSeasonalResponse,
    getAiredaleFeeds,
    getModelSuggestions,
    postForWidgetResponse,
    placeholder: el,
    articleUrl,
    attributes,
    ...restRenderProps,
  });

  return { props, type, widgetWrapper: WidgetWrapper.DIV };
};

export default Simple;
